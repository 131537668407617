import React from 'react';

const PaymentPopup = ({ closePaymentPopup, purchaseLookups, loading }) => {
  const isAnyLoading = Object.values(loading).some(value => value);

  return (
    <div className="fixed inset-0 flex justify-center bg-black bg-opacity-75 z-50 animate-fadeIn overflow-y-auto">
      <div className="bg-white text-black p-8 rounded-lg shadow-lg w-full max-w-3xl relative border border-gray-300 overflow-y-auto m-4">
        <button
          className="absolute top-4 right-4 text-black text-2xl"
          onClick={closePaymentPopup}
        >
          &times;
        </button>

        <h2 className="text-3xl font-bold mb-6 text-center">On-Demand Pricing</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="p-6 border border-gray-300 rounded-lg shadow hover:shadow-lg transition duration-200 flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-2 text-[#114e91]">1 Lookup</h3>
            <p className="text-4xl font-bold mb-4">$2.99</p>
            <p className="text-gray-600 mb-6 text-center">Single Average Spot Rate Look up</p>
            <button
              className="w-full bg-[#114e91] text-white py-3 rounded-lg hover:bg-[#12559f] transition duration-200"
              onClick={() => purchaseLookups('checkout-one-time-payment', 'oneLookup')}
              disabled={isAnyLoading}
            >
              {loading.oneLookup ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Purchase'
              )}
            </button>
          </div>
          <div className="p-6 border border-gray-300 rounded-lg shadow hover:shadow-lg transition duration-200 flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-2 text-[#114e91]">10 Lookups</h3>
            <p className="text-4xl font-bold mb-4">$7.99</p>
            <p className="text-gray-600 mb-6 text-center">10 Average Spot Rate Look ups</p>
            <button
              className="w-full bg-[#114e91] text-white py-3 rounded-lg hover:bg-[#12559f] transition duration-200"
              onClick={() => purchaseLookups('checkout-10-times-payment', '10Lookups')}
              disabled={isAnyLoading}
            >
              {loading['10Lookups'] ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Purchase'
              )}
            </button>
          </div>
          <div className="p-6 border border-gray-300 rounded-lg shadow hover:shadow-lg transition duration-200 flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-2 text-[#114e91]">25 Lookups</h3>
            <p className="text-4xl font-bold mb-4">$14.99</p>
            <p className="text-gray-600 mb-6 text-center">25 Average Spot Rate Look ups</p>
            <button
              className="w-full bg-[#114e91] text-white py-3 rounded-lg hover:bg-[#12559f] transition duration-200"
              onClick={() => purchaseLookups('checkout-25-times-payment', '25Lookups')}
              disabled={isAnyLoading}
            >
              {loading['25Lookups'] ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Purchase'
              )}
            </button>
          </div>
          <div className="p-6 border border-gray-300 rounded-lg shadow hover:shadow-lg transition duration-200 flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-2 text-[#114e91]">50 Lookups</h3>
            <p className="text-4xl font-bold mb-4">$29.99</p>
            <p className="text-gray-600 mb-6 text-center">50 Average Spot Rate Look ups</p>
            <button
              className="w-full bg-[#114e91] text-white py-3 rounded-lg hover:bg-[#12559f] transition duration-200"
              onClick={() => purchaseLookups('checkout-50-times-payment', '50Lookups')}
              disabled={isAnyLoading}
            >
              {loading['50Lookups'] ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Purchase'
              )}
            </button>
          </div>
          <div className="p-6 border border-gray-300 rounded-lg shadow hover:shadow-lg transition duration-200 flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-2 text-[#114e91]">100 Lookups</h3>
            <p className="text-4xl font-bold mb-4">$54.99</p>
            <p className="text-gray-600 mb-6 text-center">100 Average Spot Rate Look ups</p>
            <button
              className="w-full bg-[#114e91] text-white py-3 rounded-lg hover:bg-[#12559f] transition duration-200"
              onClick={() => purchaseLookups('checkout-100-times-payment', '100Lookups')}
              disabled={isAnyLoading}
            >
              {loading['100Lookups'] ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Purchase'
              )}
            </button>
          </div>
          <div className="p-6 border border-gray-300 rounded-lg shadow hover:shadow-lg transition duration-200 flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-2 text-[#114e91]">1000 Lookups</h3>
            <p className="text-4xl font-bold mb-4">$299.99</p>
            <p className="text-gray-600 mb-6 text-center">1000 Average Spot Rate Look ups</p>
            <button
              className="w-full bg-[#114e91] text-white py-3 rounded-lg hover:bg-[#12559f] transition duration-200"
              onClick={() => purchaseLookups('checkout-1000-times-payment', '1000Lookups')}
              disabled={isAnyLoading}
            >
              {loading['1000Lookups'] ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Purchase'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPopup;
